import React from "react";
import "../styles/style.css";

interface SocialMediaAccount {
  name: string;
  url: string;
  img: string;
}

const socialMediaAccounts: SocialMediaAccount[] = [
  {
    name: "Twitter",
    url: "https://corton.io/twitter",
    img: "https://corton.io/img/Twitter.png",
  },
  {
    name: "Twitch",
    url: "https://corton.io/twitch",
    img: "https://corton.io/img/Twitch.png",
  },
  {
    name: "YouTube",
    url: "https://corton.io/youtube",
    img: "https://corton.io/img/YouTube.png",
  },
  {
    name: "Instagram",
    url: "https://corton.io/instagram",
    img: "https://corton.io/img/Instagram.png",
  },
  {
    name: "Ironman Stats",
    url: "https://corton.io/stats",
    img: "https://corton.io/img/Ironman.png",
  },
];

const SocialLinks: React.FC = () => {
  return (
    <div className="social-links">
      {socialMediaAccounts.map((account) => (
        <a
          href={account.url}
          key={account.name}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={account.img} alt={account.name} />
          {account.name}
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
