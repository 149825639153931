import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "../styles/style.css";

const socialLinks: Record<string, string> = {
  twitter: "https://x.com/cxrtxn",
  youtube: "https://www.youtube.com/cxrtxn",
  instagram: "https://www.instagram.com/cxrtxn/",
  twitch: "https://twitch.tv/corton",
  ironmeme:
    "https://secure.runescape.com/m=hiscore_oldschool_ironman/hiscorepersonal?user1=Iron%20Corton",
};

const Redirects: React.FC = () => {
  const { platform } = useParams<{ platform: string }>();

  useEffect(() => {
    const redirectUrl = socialLinks[platform || ""];
    if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 2000);
    }
  }, [platform]);

  if (!platform || !socialLinks[platform]) {
    return (
      <div className="redirect-container">
        <div className="spinning-sloth">
          <span role="img" aria-label="Spinning Sloth">
            🦥
          </span>
        </div>
        <p>404 - Page Not Found</p>
      </div>
    );
  }

  return (
    <div className="redirect-container">
      <div className="spinning-sloth">
        <span role="img" aria-label="Spinning Sloth">
          🦥
        </span>
      </div>
      <p>Redirecting...</p>
    </div>
  );
};

export default Redirects;
