import React, { useState, useEffect } from "react";
import "../styles/style.css"; // Make sure this path is correct
import Logo from "./logo";

// --- Interfaces (keep these as they define the API response structure) ---
interface SkillsApiResponse {
  data: {
    info: {
      Username: string;
      "Game mode": number;
      "Last checked": string;
      player_name_with_capitalization: string;
    };
    Overall: number;
    Overall_rank: number;
    Overall_level: number;
    [key: string]: any; // Allow dynamic keys for skills
  };
}

// --- API Fetching Function (mostly unchanged, added better error typing) ---
async function getTempleOsrsPlayerStats(
  username: string
): Promise<SkillsApiResponse | null> {
  const encodedUsername = encodeURIComponent(username);
  // Using HTTPS is generally recommended if available
  const apiUrl = `https://templeosrs.com/api/player_stats.php?player=${encodedUsername}`;

  try {
    const response = await fetch(apiUrl, { method: "GET" });

    if (!response.ok) {
      console.error(
        `HTTP error fetching stats for ${username}: ${response.status} ${response.statusText}`
      );
      // Optionally, you could throw an error here to be caught below
      return null;
    }

    // Type assertion helps ensure the structure matches
    const data = (await response.json()) as SkillsApiResponse;

    // Basic validation
    if (!data || !data.data) {
      console.error(`Invalid data structure received for ${username}.`);
      return null;
    }

    return data;
  } catch (error) {
    // Catch network errors or JSON parsing errors
    console.error(`Error fetching stats for ${username}:`, error);
    return null; // Return null on error
  }
}

// --- Define the list of skills we care about ---
// Type assertion for keys of the data object that correspond to SkillDetail
const OSRS_SKILLS = [
  "Attack",
  "Defence",
  "Strength",
  "Hitpoints",
  "Ranged",
  "Prayer",
  "Magic",
  "Cooking",
  "Woodcutting",
  "Fletching",
  "Fishing",
  "Firemaking",
  "Crafting",
  "Smithing",
  "Mining",
  "Herblore",
  "Agility",
  "Thieving",
  "Slayer",
  "Farming",
  "Runecraft",
  "Hunter",
  "Construction",
];

// Helper function to capitalize skill names
const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

// --- React Component ---
const SkillsPage: React.FC = () => {
  // State to store the fetched stats
  const [playerStats, setPlayerStats] = useState<SkillsApiResponse | null>(
    null
  );
  // State to track loading status
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // State to store potential errors
  const [error, setError] = useState<string | null>(null);
  const [playerName, setPlayerName] = useState<string>("Iron Corton"); // Default player name
  const [searchInput, setSearchInput] = useState<string>("Iron Corton"); // Default search input

  // Helper function to get game mode description
  const getGameModeDescription = (gameMode: number) => {
    switch (gameMode) {
      case 0:
        return "Normal";
      case 1:
        return "Ironman";
      case 2:
        return "Ultimate Ironman";
      case 3:
        return "Hardcore Ironman";
    }
  };

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchStats = async () => {
      if (!playerName) return;
      setIsLoading(true); // Start loading
      setError(null); // Reset error state
      setPlayerStats(null); // Reset previous stats

      try {
        const stats = await getTempleOsrsPlayerStats(playerName);
        if (stats) {
          setPlayerStats(stats);
        } else {
          setError(`Could not retrieve stats for ${playerName}.`);
        }
      } catch (err) {
        // Catch any unexpected errors from the fetch function itself
        console.error("Unexpected error in fetchStats:", err);
        setError(
          `An unexpected error occurred while fetching stats for ${playerName}.`
        );
      } finally {
        setIsLoading(false); // Stop loading regardless of outcome
      }
    };

    fetchStats(); // Call the async function

    // Empty dependency array means this effect runs once on mount
  }, [playerName]); // Removed playerName dependency to fetch only once

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setPlayerName(searchInput.trim());
  };

  // --- Render Logic ---
  return (
    <div className="skills-page">
      <Logo hidetitle={true} />
      {isLoading && <div>Loading stats for {playerName}...</div>}
      {error && <div style={{ color: "red" }}>Error: {error}</div>}
      {!isLoading && !error && playerStats && playerStats.data && (
        <>
          <h2>
            Skills for {playerStats.data.info.player_name_with_capitalization}
          </h2>
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Enter player name"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="search-box"
            />
            <button type="submit" className="search-button">
              Search
            </button>
          </form>
          <p>
            Overall Level: {playerStats.data.Overall_level} (Rank:{" "}
            {playerStats.data.Overall_rank}, XP:{" "}
            {playerStats.data.Overall.toLocaleString()})
          </p>
          <table className="skills-table">
            <thead>
              <tr>
                <th>Skill</th>
                <th>Level</th>
                <th>XP</th>
              </tr>
            </thead>
            <tbody>
              {OSRS_SKILLS.map((skillName) => {
                const level = playerStats.data[`${skillName}_level`] ?? "N/A";
                const xp = playerStats.data[skillName] ?? "N/A";
                return (
                  <tr key={skillName}>
                    <td>{capitalize(skillName)}</td>
                    <td>{level >= 99 ? <b>{level}</b> : level}</td>
                    <td>{xp.toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p>
            Game Mode:{" "}
            {getGameModeDescription(playerStats.data.info["Game mode"])}
          </p>
          <p>Last Checked: {playerStats.data.info["Last checked"]}</p>
        </>
      )}
    </div>
  );
};

export default SkillsPage; // Renamed component to follow convention (PascalCase)
