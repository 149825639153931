import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Logo from "./components/logo";
import SocialLinks from "./components/sociallinks";
import Foot from "./components/footer";
import Redirects from "./components/redirects";
import Skills from "./components/skills";
import "./styles/style.css";

const App: React.FC = () => (
  <Router>
    <div className="app">
      <Routes>
        <Route path=":platform" element={<Redirects />} />
        <Route
          path="/"
          element={
            <>
              <Logo />
              <SocialLinks />
              <Foot />
            </>
          }
        />
        <Route path="/stats" element={<Skills />} />
      </Routes>
    </div>
  </Router>
);

export default App;
