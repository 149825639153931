import React, { useState, useEffect } from "react";
import "../styles/style.css";

const slothFacts = [
  "Sloths are so slow that algae grows on their fur!",
  "A sloth can hold its breath for up to 40 minutes by slowing its heart rate.",
  "Sloths only poop once a week, and it’s a big event!",
  "There are two types of sloths: two-toed and three-toed sloths.",
  "Sloths can turn their heads almost 270 degrees.",
  "Baby sloths are born with claws that they use to cling to their moms.",
  "Sloths spend about 90% of their lives hanging upside down.",
  "Sloths have more neck vertebrae than giraffes, allowing them to rotate their heads further.",
  "Sloths are excellent swimmers, despite their slow pace on land.",
  "Sloths have a low metabolic rate, which helps them conserve energy.",
  "A sloth’s fur is a mini-ecosystem, hosting algae, moths, and beetles.",
  "The algae in sloth fur provides camouflage by giving it a greenish tint.",
  "Sloths sleep 15-20 hours a day on average.",
  "A sloth’s grip is so strong, they often remain clinging to branches even after death.",
  "Sloths are native to Central and South America.",
  "Sloths have specialized claws that help them hang from trees effortlessly.",
  "It can take up to a month for a sloth to digest a single meal.",
  "Sloths’ slow movement makes them almost invisible to predators.",
  "Sloths are surprisingly good swimmers and can move three times faster in water than on land.",
  "A group of sloths is called a 'snuggle.'",
  "Sloths have been around for about 35 million years.",
  "The ancient giant ground sloth was as large as an elephant!",
  "Sloths have no incisor or canine teeth, just peg-like molars.",
  "A sloth’s stomach is divided into compartments like a cow’s, aiding digestion.",
  "Sloths are solitary animals, preferring to live alone.",
  "Sloths can starve if they eat leaves that are too low in nutrients.",
  "Sloths’ slow pace is an adaptation to their low-energy diet of leaves.",
  "Three-toed sloths have an extra vertebra in their neck compared to two-toed sloths.",
  "Sloths rely on their camouflage to avoid predators rather than trying to escape.",
  "Sloths have a symbiotic relationship with the algae that grows on them.",
  "The slow metabolism of sloths means they don’t need to eat much.",
  "Sloths’ long claws can grow up to 4 inches in length.",
  "Baby sloths learn what to eat by licking their mother’s lips.",
  "Sloths have poor eyesight and hearing but an excellent sense of smell.",
  "Sloths can survive falls of up to 100 feet unharmed.",
  "The word 'sloth' comes from the Latin word 'acedia,' meaning laziness or indifference.",
  "Despite their reputation, sloths are actually quite strong for their size.",
  "A sloth’s fur provides a habitat for various insects, including sloth moths.",
  "Sloths’ curved claws make it difficult for them to walk on the ground.",
  "Two-toed sloths are generally larger than three-toed sloths.",
  "Sloths are most closely related to anteaters and armadillos.",
  "A sloth’s slow movements make them a tough target for predators.",
  "Sloths have fewer than half the muscle mass of similar-sized animals.",
  "The greenish tint from algae helps sloths blend into the canopy.",
  "Sloths can live up to 40 years in captivity.",
  "Sloths’ noses are adapted to breathe easily while hanging upside down.",
  "Some species of sloths are critically endangered due to habitat loss.",
  "Sloths spend most of their time in the trees and come down only to defecate or change trees.",
  "Sloths’ teeth never stop growing, but their diet of tough leaves keeps them worn down.",
  "Sloths use their long claws to defend themselves if attacked.",
  "Sloths’ fur grows in the opposite direction compared to most mammals, aiding in water runoff.",
  "Sloths have been featured in mythology and folklore throughout Central and South America.",
  "Sloths are surprisingly agile when climbing trees.",
  "A sloth’s diet consists mainly of leaves, twigs, and fruit.",
  "Sloths can close their nostrils completely to prevent water from entering while swimming.",
];

const getRandomFact = () => {
  const randomIndex = Math.floor(Math.random() * slothFacts.length);
  return slothFacts[randomIndex];
};

const Footer: React.FC = () => {
  const [fact, setFact] = useState(getRandomFact());

  useEffect(() => {
    const interval = setInterval(() => {
      setFact(getRandomFact());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="footer">
      <p>{fact}</p>
    </footer>
  );
};

export default Footer;
