import React from "react";
import "../styles/style.css";

const defaultTitle = "Corton's Link!";

interface LogoProps {
  hidetitle?: boolean;
  title?: string;
}

const Logo: React.FC<LogoProps> = ({
  hidetitle = false,
  title = defaultTitle,
}) => (
  <div className="logo-area">
    <img className="logo" src="./img/logo.png" alt="Logo" />
    {!hidetitle && <h2>{title}</h2>}
  </div>
);

export default Logo;
